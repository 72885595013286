import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransModal = createTrans({
  bookingFeeDeletionDescription: (
    <Trans
      i18nKey="modal.title.bookingFeesDeletionDescription"
      defaults="Are you sure you want to delete this booking fee? It cannot be undone."
    />
  ),
  bookingFeesDeletionDescription: (
    <Trans
      i18nKey="modal.title.bookingFeesDeletionDescription"
      defaults="Are you sure you want to delete selected booking fees? It cannot be undone."
    />
  ),
  bookingTtlDeletionDescription: (
    <Trans
      i18nKey="modal.title.bookingTtlDeletionDescription"
      defaults="Are you sure you want to delete this booking time to live? It cannot be undone."
    />
  ),
  deleteBookingFee: (
    <Trans
      i18nKey="modal.title.deleteBookingFee"
      defaults="Delete booking fee"
    />
  ),
  deleteBookingFees: (
    <Trans
      i18nKey="modal.title.deleteBookingFees"
      defaults="Delete booking fees"
    />
  ),
  deleteBookingTtl: (
    <Trans
      i18nKey="modal.title.deleteBookingTtl"
      defaults="Delete booking time to live"
    />
  ),
  deleteOnHoldBooking: (
    <Trans
      i18nKey="modal.title.deleteOnHoldBooking"
      defaults="Delete on-hold booking configuration"
    />
  ),
  deleteOnHoldBookings: (
    <Trans
      i18nKey="modal.title.deleteOnHoldBookings"
      defaults="Delete on-hold booking configurations"
    />
  ),
  deleteTicketText: (
    <Trans
      i18nKey="modal.title.deleteTicketText"
      defaults="Delete ticket text"
    />
  ),
  deleteTicketTextLocalizations: (
    <Trans
      i18nKey="modal.title.deleteTicketTextLocalizations"
      defaults="Delete ticket text localizations"
    />
  ),
  deleteTicketTexts: (
    <Trans
      i18nKey="modal.title.deleteTicketTexts"
      defaults="Delete ticket texts"
    />
  ),
  onHoldBookingDeletionDescription: (
    <Trans
      i18nKey="modal.title.onHoldBookingDeletionDescription"
      defaults="Are you sure you want to delete this on-hold booking configuration? It cannot be undone."
    />
  ),
  onHoldBookingsDeletionDescription: (
    <Trans
      i18nKey="modal.title.onHoldBookingsDeletionDescription"
      defaults="Are you sure you want to delete selected on-hold booking configuration? It cannot be undone."
    />
  ),
  placeholder: <Trans i18nKey="modal.placeholder" defaults="Placeholder" />,
  ticketTextDeletionDescription: (
    <Trans
      i18nKey="modal.title.ticketTextDeletionDescription"
      defaults="Are you sure you want to delete this ticket text? It cannot be undone."
    />
  ),
  ticketTextLocalizationsDeletionDescription: (
    <Trans
      i18nKey="modal.title.ticketTextLocalizationsDeletionDescription"
      defaults="Are you sure you want to delete selected ticket text localizations? It cannot be undone."
    />
  ),
  ticketTextsDeletionDescription: (
    <Trans
      i18nKey="modal.title.ticketTextsDeletionDescription"
      defaults="Are you sure you want to delete selected ticket texts? It cannot be undone."
    />
  ),
});
