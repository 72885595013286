import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransSubtitle = createTrans({
  bookingFeesQty: (
    <Trans i18nKey="subTitle.bookingFeesQty" defaults="{{num}} booking fees" />
  ),
  editBookingFee: (
    <Trans
      i18nKey="subTitle.editBookingFee"
      defaults="Edit booking fee configuration"
    />
  ),
  editTimeToLive: (
    <Trans i18nKey="subTitle.editTimeToLive" defaults="Edit time to live" />
  ),
  localizations: (
    <Trans i18nKey="subTitle.localizations" defaults="Localizations" />
  ),
  newTimeToLive: (
    <Trans i18nKey="subTitle.newTimeToLive" defaults="New time to live" />
  ),
  newBookingFee: (
    <Trans
      i18nKey="subTitle.newBookingFee"
      defaults="New booking fee configuration"
    />
  ),
  newOnHoldBookingConfiguration: (
    <Trans
      i18nKey="subTitle.newOnHoldBookingConfiguration"
      defaults="New on-hold booking configuration"
    />
  ),
  newTicketText: (
    <Trans i18nKey="subTitle.newTicketText" defaults="New ticket text" />
  ),
  configurationsQty: (
    <Trans
      i18nKey="subTitle.configurationsQty"
      defaults="{{num}} configurations"
    />
  ),
  placeholder: <Trans i18nKey="subTitle.placeholder" defaults="Placeholder" />,
  search: <Trans i18nKey="subTitle.search" defaults="Search" />,
  searchResults: (
    <Trans i18nKey="subTitle.searchResults" defaults="Search results" />
  ),
  selectedQty: (
    <Trans i18nKey="subtitle.selectedQty" defaults="{{num}} selected" />
  ),
  stopsQty: <Trans i18nKey="subtitle.stopsQty" defaults="{{num}} stops" />,
  ticketTextsQty: (
    <Trans i18nKey="subTitle.ticketTextsQty" defaults="{{num}} ticket texts" />
  ),
});
