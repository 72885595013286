import { FC } from 'react';
import { useSelector } from 'store/utils';
import { onHoldBookingsLoadingSelector } from 'features/loading/loadingSelectors';
import { AddButton, CardHeader, Layout, Loadable } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { Route, Switch, useHistory } from 'react-router-dom';
import { TransButton } from 'i18n/trans/button';
import { OnHoldBookingConfigurationDetails } from 'routes/OnHoldBookingConfigurations/OnHoldBookingConfigurationDetails';
import { OnHoldBookingConfigurationTable } from 'routes/OnHoldBookingConfigurations/OnHoldBookingConfigurationTable';

export const OnHoldBookingConfigurations: FC = () => {
  const history = useHistory();
  const loading = useSelector(onHoldBookingsLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader
            title={<TransTitle i18nKey="onHoldBookingConfigurations" />}
          >
            <AddButton onClick={() => history.push('/on-hold-bookings/create')}>
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path="/on-hold-bookings/:action(create|edit)/:id?"
              component={OnHoldBookingConfigurationDetails}
              exact
            />
          </Switch>
        }
      >
        <OnHoldBookingConfigurationTable />
      </Layout>
    </Loadable>
  );
};
