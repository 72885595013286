import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import {
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import {
  Button,
  ConfirmDeleteModal,
  convertNumberToTimeDuration,
  convertTimeDurationToNumber,
  Drawer,
  FormProvider,
  formSubmit,
  Icon,
  SelectField,
  TimeDurationField,
  TimeUnit,
  Tooltip,
  useForm,
  useModal,
} from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { useHistory, useParams } from 'react-router-dom';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { TransField } from 'i18n/trans/field';
import { TimeToLiveValues } from 'dto/timeToLive';
import {
  createTTLConfiguration,
  deleteTTLConfiguration,
  getTTLConfiguration,
  getTTLConfigurations,
  setTTLConfiguration,
  updateTTLConfiguration,
} from 'features/timeToLive/timeToLiveActions';
import { useDispatch, useSelector } from 'store/utils';
import { ttlConfigurationSelector } from 'features/timeToLive/timeToLiveSelectors';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransModal } from 'i18n/trans/modal';

const useStyles = makeStyles(
  (theme) => ({
    action: { alignSelf: 'flex-start' },
    form: {
      height: '100%',
      width: '424px !important',
      minWidth: '0 !important',
    },
    content: {
      flex: 1,
    },
    actions: {
      padding: theme.spacing(3),
      justifyContent: 'flex-end',
      boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
    },
  }),
  {
    name: 'TimeToLiveConfigurationDetails',
  }
);

interface TimeToLiveConfigurationDetailsProps {}

export const TimeToLiveConfigurationDetails: FC<TimeToLiveConfigurationDetailsProps> =
  () => {
    const { action, id } =
      useParams<{ action: 'create' | 'edit'; id?: string }>();
    const history = useHistory();
    const alert = useAlert();
    const dispatch = useDispatch();
    const { open: isOpen, onOpen, onClose } = useModal();
    const isEditing = useMemo(() => action === 'edit', [action]);

    useEffect(() => {
      dispatch(setTTLConfiguration());
      if (action === 'edit' && id) {
        dispatch(getTTLConfiguration(id));
      }

      return () => {
        dispatch(setTTLConfiguration());
      };
    }, [action, dispatch, id]);

    const ttlConfiguration = useSelector(ttlConfigurationSelector);
    const salesChannelOptions = useClassificationOptions(
      ClassificationGroup.SALES_CHANNEL
    );
    const initialValues = useMemo(
      () => ({
        ...(ttlConfiguration && {
          salesChannelId: ttlConfiguration.salesChannel.id,
          timeToLive: convertNumberToTimeDuration(
            ttlConfiguration.timeToLiveInMinutes
          ),
        }),
      }),
      [ttlConfiguration]
    );

    const onSubmit = useCallback(
      (payload: TimeToLiveValues) =>
        formSubmit(async () => {
          await dispatch(
            (ttlConfiguration?.id
              ? updateTTLConfiguration
              : createTTLConfiguration)({
              ...payload,
              timeToLiveInMinutes: convertTimeDurationToNumber(
                payload.timeToLive
              ),
            })
          ).unwrap();

          if (!id) {
            alert.success(<TransAlert i18nKey="bookingTtlCreated" />);
          } else {
            alert.success(<TransAlert i18nKey="bookingTtlUpdated" />);
          }
          history.replace('/ttl');
          await dispatch(getTTLConfigurations());
        }),
      [alert, dispatch, history, id, ttlConfiguration?.id]
    );

    const handleDelete = useCallback(async () => {
      await dispatch(deleteTTLConfiguration()).unwrap();

      alert.success(<TransAlert i18nKey="bookingTtlDeleted" />);
      history.replace('/ttl');
    }, [alert, dispatch, history]);

    const { form, handleSubmit, submitting } = useForm<TimeToLiveValues>({
      initialValues,
      onSubmit,
      subscription: { submitting: true },
    });

    const closeDrawer = useCallback(() => {
      history.replace('/ttl');
    }, [history]);

    const classes = useStyles();

    return (
      <Drawer anchor="right" elevation={0} onClose={closeDrawer} open>
        <FormProvider {...form}>
          <Stack
            className={classes.form}
            component="form"
            onSubmit={handleSubmit}
          >
            <CardHeader
              sx={{ pb: 0 }}
              classes={{ action: classes.action }}
              title={
                <Typography variant="subtitle" component="div">
                  {isEditing ? (
                    <TransSubtitle i18nKey="editTimeToLive" />
                  ) : (
                    <TransSubtitle i18nKey="newTimeToLive" />
                  )}
                </Typography>
              }
              action={
                <IconButton aria-label="close" onClick={closeDrawer}>
                  <Tooltip
                    content={<TransButton i18nKey="close" />}
                    delay={500}
                  >
                    <Icon name="close" size={24} />
                  </Tooltip>
                </IconButton>
              }
            />
            <CardContent className={classes.content}>
              {ttlConfiguration?.id && (
                <>
                  <Button
                    size="small"
                    variant="text"
                    color="error"
                    startIcon={<Icon name="delete" size={16} />}
                    onClick={onOpen}
                  >
                    <TransButton i18nKey="delete" />
                  </Button>
                  <ConfirmDeleteModal
                    handleDelete={handleDelete}
                    title={<TransModal i18nKey="deleteBookingTtl" />}
                    description={
                      <TransModal i18nKey="bookingTtlDeletionDescription" />
                    }
                    isOpen={isOpen}
                    onClose={onClose}
                  />
                </>
              )}
              <SelectField
                name="salesChannelId"
                label={<TransField i18nKey="salesChannel" />}
                options={salesChannelOptions}
                required
                margin="normal"
              />
              <TimeDurationField
                name="timeToLive"
                label={<TransField i18nKey="timeToLive" />}
                excludedUnits={[TimeUnit.SECONDS]}
                required
                margin="normal"
              />
            </CardContent>
            <CardActions className={classes.actions}>
              <Button variant="text" color="primary" onClick={closeDrawer}>
                <TransButton i18nKey="cancel" />
              </Button>
              {id ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<Icon name="check" />}
                >
                  <TransButton i18nKey="save" disabled={submitting} />
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitting}
                  startIcon={<Icon name="plus" />}
                >
                  <TransButton i18nKey="add" />
                </Button>
              )}
            </CardActions>
          </Stack>
        </FormProvider>
      </Drawer>
    );
  };
