import { createAsyncThunk } from 'store/utils';
import {
  TicketText,
  TicketTextFilter,
  TicketTextLocalization,
  TicketTextValuesRequest,
} from 'dto/ticketTexts';
import { Pagination } from '@fleet/shared/dto/pagination';
import { createAction } from '@reduxjs/toolkit';
import { api } from '@fleet/shared';
import { transformStop } from 'hooks/useTicketTextsForm';

export const setTicketTextsFilter = createAction<Partial<TicketTextFilter>>(
  'ticketText/setTicketTextsFilter'
);

export const clearTicketTexts = createAction('ticketText/clearTicketTexts');

export const getTicketTexts = createAsyncThunk<
  Pagination<TicketText>,
  Partial<TicketTextFilter> | undefined
>('ticketText/search', async (values, { dispatch, getState }) => {
  values && dispatch(setTicketTextsFilter(values));
  const {
    common: { currentBusinessEntityId },
    ticketText: { filter },
  } = getState();

  return (
    await api.post<Pagination<TicketText>>('/ticket-texts/search', {
      carrierId: currentBusinessEntityId,
      ...transformStop(filter),
    })
  ).data;
});

export const setTicketText = createAction<TicketText | undefined>(
  'ticketText/setTicketText'
);

export const getTicketText = createAsyncThunk<TicketText, string | number>(
  'ticketText/getTicketText',
  async (id, { dispatch }) => {
    const { data } = await api.get(`/ticket-texts/${id}`);
    dispatch(setTicketText(data));

    return data;
  }
);

export const createTicketText = createAsyncThunk<
  TicketText,
  Omit<TicketTextValuesRequest, 'id'>
>('ticketText/createTicketText', async (payload) => {
  const { data } = await api.post<TicketText>(`/ticket-texts`, payload);

  return data;
});

export const updateTicketText = createAsyncThunk<
  TicketText,
  TicketTextValuesRequest
>('ticketText/updateTicketText', async ({ id, ...payload }) => {
  const { data } = await api.put<TicketText>(`/ticket-texts/${id}`, payload);

  return data;
});

export const deleteTicketText = createAsyncThunk<void, TicketText['id']>(
  'ticketText/deleteTicketText',
  async (id, { dispatch }) => {
    await api.delete(`/ticket-texts/${id}`);
    await dispatch(getTicketTexts());
  }
);

export const deleteTicketTextsBulk = createAsyncThunk<void, TicketText['id'][]>(
  'ticketText/deleteTicketTextsBulk',
  async (ids, { dispatch }) => {
    await Promise.all(ids.map((id) => api.delete(`/ticket-texts/${id}`)));
    await dispatch(getTicketTexts());
  }
);

export const updateOrCreateTicketTextLocalization = createAsyncThunk<
  TicketTextLocalization,
  { id: TicketText['id']; payload: TicketTextLocalization }
>(
  'ticketText/updateOrCreateTicketTextLocalization',
  async (
    { id: ticketTextId, payload: { id, culture, ...payload } },
    { dispatch }
  ) => {
    const { data } = await (id ? api.put : api.post)(
      `/ticket-texts/${ticketTextId}/localizations`,
      { ...payload, cultureId: culture?.id }
    );
    await dispatch(getTicketText(ticketTextId));

    return data;
  }
);

export const deleteTicketTextLocalization = createAsyncThunk<
  void,
  { id: TicketTextLocalization['id']; ticketTextId: TicketText['id'] }
>(
  'ticketText/deleteTicketTextLocalization',
  async ({ id, ticketTextId }, { dispatch }) => {
    await api.delete(`/ticket-texts/${ticketTextId}/localizations/${id}`);
    await dispatch(getTicketText(ticketTextId));
  }
);

export const deleteTicketTextLocalizationsBulk = createAsyncThunk<
  void,
  { ids: Array<TicketTextLocalization['id']>; ticketTextId: TicketText['id'] }
>(
  'ticketText/deleteTicketTextLocalizationsBulk',
  async ({ ids, ticketTextId }) => {
    await Promise.all(
      ids.map((id) =>
        api.delete(`/ticket-texts/${ticketTextId}/localizations/${id}`)
      )
    );
  }
);
