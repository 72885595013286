import {
  Button,
  ConfirmDeleteModal,
  Icon,
  SearchResult,
  useModal,
  useRowActive,
  useRowSelectCheckbox,
} from '@fleet/shared';
import { Table, TableColumns } from '@fleet/shared/components/Table';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { TicketText } from 'dto/ticketTexts';
import {
  deleteTicketTextsBulk,
  getTicketTexts,
} from 'features/ticketText/ticketTextActions';
import {
  ticketTextsFilterSelector,
  ticketTextsSelector,
} from 'features/ticketText/ticketTextSelectors';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { TicketTextSearchForm } from 'routes/TicketTexts/TicketTextSearchForm';
import { useDispatch, useSelector } from 'store/utils';
import {
  CardContent,
  Divider,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Row, usePagination, useRowSelect, useTable } from 'react-table';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransModal } from 'i18n/trans/modal';
import { ticketTextsLoadingSelector } from 'features/loading/loadingSelectors';

interface TicketTextsTableProps {}

export const TicketTextTable: FC<TicketTextsTableProps> = () => {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const { open: isOpen, onOpen, onClose } = useModal();
  const ticketTexts = useSelector(ticketTextsSelector);
  const filter = useSelector(ticketTextsFilterSelector);
  const loading = useSelector(ticketTextsLoadingSelector);
  const data = useMemo(() => ticketTexts?.items ?? [], [ticketTexts]);

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      dispatch(
        getTicketTexts({
          ...filter,
          ...paginationParams,
        })
      ),
    [dispatch, filter]
  );

  const link = useCallback(
    (row: Row<TicketText>) => `/ticket-texts/edit/${row.original.id}`,
    []
  );

  const getPage = useCallback(
    (pageSize: number) => {
      if (ticketTexts) {
        const { limit = pageSize, offset } = ticketTexts;
        return offset / limit;
      }

      return 0;
    },
    [ticketTexts]
  );

  const columns = useMemo<TableColumns<TicketText>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        Cell: ({ row, value }) => (
          <MuiLink underline="none" component={Link} to={link(row)}>
            {value}
          </MuiLink>
        ),
      },
      {
        id: 'ticketTextType.name',
        accessor: ({ ticketTextType }) => ticketTextType?.name,
        Header: <TransTableHead i18nKey="ticketTextType" />,
      },
    ],
    [link]
  );

  const table = useTable<TicketText>(
    {
      data,
      columns,
      pageCount: -1,
      total: ticketTexts?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
    },
    usePagination,
    useRowActive,
    useRowSelect,
    useRowSelectCheckbox
  );

  const { activeFlatRow, resetRowActive, selectedFlatRows } = table;

  useEffect(() => {
    if (activeFlatRow) {
      history.replace(link(activeFlatRow));
    }
  }, [activeFlatRow, history, link]);

  useEffect(() => {
    if (!id) {
      resetRowActive();
    }
  }, [id, resetRowActive]);

  const handleRowsRemove = useCallback(async () => {
    await dispatch(
      deleteTicketTextsBulk(selectedFlatRows.map(({ original }) => original.id))
    ).unwrap();
    onClose();
    alert.success(<TransAlert i18nKey="ticketTextDeleted" />);
  }, [dispatch, selectedFlatRows, onClose, alert]);

  return (
    <>
      <TicketTextSearchForm />
      <Divider />
      <SearchResult results={!!data.length} loading={loading}>
        <Table
          caption={
            <>
              <CardContent>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center">
                    <Typography variant="subtitle" fontWeight="700">
                      <TransSubtitle i18nKey="searchResults" />
                    </Typography>
                    {Boolean(ticketTexts?.totalCount) && (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ ml: 2 }}
                      >
                        <TransSubtitle
                          i18nKey="ticketTextsQty"
                          values={{
                            num: ticketTexts ? ticketTexts.totalCount : 0,
                          }}
                        />
                      </Typography>
                    )}
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Typography variant="body2" color="text.secondary">
                      <TransSubtitle
                        i18nKey="selectedQty"
                        values={{
                          num: table.selectedFlatRows.length,
                        }}
                      />
                    </Typography>
                    <Button
                      variant="text"
                      startIcon={<Icon name="delete" />}
                      onClick={onOpen}
                      disabled={table.selectedFlatRows.length === 0}
                      color="error"
                    >
                      <TransButton i18nKey="delete" />
                    </Button>
                  </Stack>
                </Stack>
              </CardContent>
            </>
          }
          table={table}
        />
        <ConfirmDeleteModal
          handleDelete={handleRowsRemove}
          title={<TransModal i18nKey="deleteTicketTexts" />}
          description={<TransModal i18nKey="ticketTextsDeletionDescription" />}
          isOpen={isOpen}
          onClose={onClose}
        />
      </SearchResult>
    </>
  );
};
