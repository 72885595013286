import { AddButton, CardHeader, Layout, Loadable } from '@fleet/shared';
import { bookingFeesLoadingSelector } from 'features/loading/loadingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';
import { FC } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { BookingFeeDetails } from 'routes/BookingFee/BookingFeeDetails';
import { BookingFeeTable } from 'routes/BookingFee/BookingFeeTable';
import { useSelector } from 'store/utils';

interface BookingFeeProps {}

export const BookingFee: FC<BookingFeeProps> = () => {
  const history = useHistory();
  const loading = useSelector(bookingFeesLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader title={<TransTitle i18nKey="bookingFee" />}>
            <AddButton onClick={() => history.push('/booking-fee/create')}>
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path="/booking-fee/:action(create|edit)/:id?"
              component={BookingFeeDetails}
              exact
            />
          </Switch>
        }
      >
        <BookingFeeTable />
      </Layout>
    </Loadable>
  );
};
