import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransAlert = createTrans({
  bookingFeeCreated: (
    <Trans
      i18nKey="alert.bookingFeeCreated"
      defaults="Booking fee was created"
    />
  ),
  bookingFeeDeleted: (
    <Trans
      i18nKey="alert.bookingFeeDeleted"
      defaults="Booking fee was deleted"
    />
  ),
  bookingFeeUpdated: (
    <Trans
      i18nKey="alert.bookingFeeUpdated"
      defaults="Booking fee was updated"
    />
  ),
  bookingTtlCreated: (
    <Trans
      i18nKey="alert.bookingTtlCreated"
      defaults="Booking time to live was created"
    />
  ),
  bookingTtlDeleted: (
    <Trans
      i18nKey="alert.bookingTtlDeleted"
      defaults="Booking time to live was deleted"
    />
  ),
  bookingTtlUpdated: (
    <Trans
      i18nKey="alert.bookingTtlUpdated"
      defaults="Booking time to live was updated"
    />
  ),
  fillAtLeastOne: (
    <Trans
      i18nKey="alert.fillAtLeastOne"
      defaults="At least one filter must be filled"
    />
  ),
  onHoldBookingCreated: (
    <Trans
      i18nKey="alert.onHoldBookingCreated"
      defaults="On-hold booking was created"
    />
  ),
  onHoldBookingDeleted: (
    <Trans
      i18nKey="alert.onHoldBookingDeleted"
      defaults="On-hold booking was deleted"
    />
  ),
  onHoldBookingUpdated: (
    <Trans
      i18nKey="alert.onHoldBookingUpdated"
      defaults="On-hold booking was updated"
    />
  ),
  ticketTextCreated: (
    <Trans
      i18nKey="alert.ticketTextCreated"
      defaults="Ticket text {{name}} created"
    />
  ),
  ticketTextDeleted: (
    <Trans
      i18nKey="alert.ticketTextDeleted"
      defaults="Ticket text was deleted"
    />
  ),
  ticketTextUpdated: (
    <Trans
      i18nKey="alert.ticketTextUpdated"
      defaults="Ticket text {{name}} updated"
    />
  ),
});
