import { OnHoldBookingConfigurationSearchForm } from 'routes/OnHoldBookingConfigurations/OnHoldBookingConfigurationSearchForm';
import { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react';
import { Table } from '@fleet/shared/components/Table';
import {
  CardContent,
  Divider,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  Button,
  Checkbox,
  ConfirmDeleteModal,
  Icon,
  SearchResult,
  TableColumns,
  useModal,
  useRowActive,
  useRowSelectCheckbox,
} from '@fleet/shared';
import {
  onHoldBookingConfigurationsFilterSelector,
  onHoldBookingConfigurationsSelector,
} from 'features/onHoldBookingConfiguration/onHoldBookingConfigurationSelectors';
import { useDispatch, useSelector } from 'store/utils';
import { OnHoldBookingConfiguration } from 'dto/onHoldBookingConfiguration';
import { TransTableHead } from 'i18n/trans/table';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Row,
  useFilters,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import {
  deleteOnHoldBookingConfigurations,
  getOnHoldBookingConfigurations,
} from 'features/onHoldBookingConfiguration/onHoldBookingConfigurationActions';
import { TransButton } from 'i18n/trans/button';
import { formatDate } from '@fleet/shared/utils/date';
import { onHoldBookingLoadingSelector } from 'features/loading/loadingSelectors';
import { useValidAccessor } from 'hooks/useValidAccessor';
import { TransField } from 'i18n/trans/field';
import { TransModal } from 'i18n/trans/modal';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';

export const OnHoldBookingConfigurationTable: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const { open: isOpen, onOpen, onClose } = useModal();

  const onHoldBookings = useSelector(onHoldBookingConfigurationsSelector);
  const data = useMemo(() => onHoldBookings?.items ?? [], [onHoldBookings]);
  const filter = useSelector(onHoldBookingConfigurationsFilterSelector);
  const loading = useSelector(onHoldBookingLoadingSelector);
  const isValidAccessor = useValidAccessor();

  const link = useCallback(
    (row: Row<OnHoldBookingConfiguration>) =>
      `/on-hold-bookings/edit/${row.original.id}`,
    []
  );

  const columns = useMemo<TableColumns<OnHoldBookingConfiguration>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        Cell: ({ row, value }) => (
          <MuiLink underline="none" component={Link} to={link(row)}>
            {value}
          </MuiLink>
        ),
      },
      {
        accessor: 'isMain',
        Header: <TransTableHead i18nKey="isMain" />,
        Cell: ({ value }) =>
          value ? <Icon name="check" color="success" /> : '-',
      },
      {
        id: 'ticketPaymentDeadlineType.id',
        accessor: ({ ticketPaymentDeadlineType }) =>
          ticketPaymentDeadlineType?.name,
        Header: <TransTableHead i18nKey="ticketPaymentDeadlineType" />,
        width: 170,
      },
      {
        id: 'validityPeriod.from',
        accessor: ({ validityPeriod }) =>
          validityPeriod?.from ? formatDate(validityPeriod.from) : '-',
        Header: <TransTableHead i18nKey="validFrom" />,
      },
      {
        id: 'validityPeriod.to',
        accessor: ({ validityPeriod }) =>
          validityPeriod?.to ? formatDate(validityPeriod.to) : '-',
        Header: <TransTableHead i18nKey="validTo" />,
      },
      {
        id: 'isValid',
        accessor: isValidAccessor,
      },
    ],
    [isValidAccessor, link]
  );

  const getPage = useCallback(
    (pageSize: number) => {
      if (onHoldBookings) {
        const { limit = pageSize, offset } = onHoldBookings;
        return offset / limit;
      }

      return 0;
    },
    [onHoldBookings]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(
        getOnHoldBookingConfigurations({ ...filter, ...paginationParams })
      ).unwrap(),
    [dispatch, filter]
  );

  const table = useTable<OnHoldBookingConfiguration>(
    {
      data,
      columns,
      initialState: {
        hiddenColumns: ['isValid'],
      },
      pageCount: -1,
      total: onHoldBookings?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
    },
    useFilters,
    usePagination,
    useRowActive,
    useRowSelect,
    useRowSelectCheckbox
  );

  const { activeFlatRow, resetRowActive, selectedFlatRows } = table;

  useEffect(() => {
    if (activeFlatRow) {
      history.replace(link(activeFlatRow));
    }
  }, [activeFlatRow, history, link]);

  useEffect(() => {
    if (!id) {
      resetRowActive();
    }
  }, [id, resetRowActive]);

  const handleValidFilterToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      table.setFilter('isValid', e.target.checked || undefined),
    [table]
  );

  const handleRowsRemove = useCallback(async () => {
    await dispatch(
      deleteOnHoldBookingConfigurations(
        selectedFlatRows.map(({ original }) => original.id)
      )
    ).unwrap();

    onClose();
    alert.success(<TransAlert i18nKey="onHoldBookingDeleted" />);
  }, [alert, dispatch, onClose, selectedFlatRows]);

  return (
    <>
      <OnHoldBookingConfigurationSearchForm />
      <Divider />
      <SearchResult results={data.length} loading={loading}>
        <Table
          caption={
            <>
              <CardContent>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    sx={{ gap: '15px' }}
                  >
                    <Typography variant="subtitle" fontWeight="700">
                      <TransSubtitle i18nKey="searchResults" />
                    </Typography>

                    <div>
                      <Checkbox
                        onChange={handleValidFilterToggle}
                        label={
                          <Typography
                            variant="body2"
                            color="text.primary"
                            component="span"
                          >
                            <TransField i18nKey="showOnlyValidConfigurations" />
                          </Typography>
                        }
                        size="small"
                      />
                    </div>

                    {Boolean(onHoldBookings?.totalCount) && (
                      <Typography variant="body2" color="text.secondary">
                        <TransSubtitle
                          i18nKey="configurationsQty"
                          values={{
                            num: onHoldBookings ? onHoldBookings.totalCount : 0,
                          }}
                        />
                      </Typography>
                    )}
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Typography variant="body2" color="text.secondary">
                      <TransSubtitle
                        i18nKey="selectedQty"
                        values={{
                          num: table.selectedFlatRows.length,
                        }}
                      />
                    </Typography>
                    <Button
                      variant="text"
                      startIcon={<Icon name="delete" />}
                      onClick={onOpen}
                      disabled={table.selectedFlatRows.length === 0}
                      color="error"
                    >
                      <TransButton i18nKey="delete" />
                    </Button>
                  </Stack>
                </Stack>
              </CardContent>
            </>
          }
          table={table}
        />
        <ConfirmDeleteModal
          handleDelete={handleRowsRemove}
          title={<TransModal i18nKey="deleteOnHoldBookings" />}
          description={
            <TransModal i18nKey="onHoldBookingsDeletionDescription" />
          }
          isOpen={isOpen}
          onClose={onClose}
        />
      </SearchResult>
    </>
  );
};
