import {
  Button,
  ConfirmDeleteModal,
  FormProvider,
  Icon,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useModal,
  useRowEditActions,
} from '@fleet/shared';
import { Stack } from '@mui/material';
import { ClassificationGroup } from 'dto/classification';
import { TicketTextLocalization } from 'dto/ticketTexts';
import {
  deleteTicketTextLocalizationsBulk,
  getTicketText,
  updateOrCreateTicketTextLocalization,
} from 'features/ticketText/ticketTextActions';
import { currentTicketTextSelector } from 'features/ticketText/ticketTextSelectors';
import {
  useClassificationMap,
  useClassificationOptions,
} from 'hooks/useClassificationOptions';
import { TransButton } from 'i18n/trans/button';
import { TransModal } from 'i18n/trans/modal';
import { TransTableHead } from 'i18n/trans/table';
import { useCallback, useMemo } from 'react';
import { useFilters, useRowSelect } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';

export const TicketTextDetailsLocalization = () => {
  const { open: isOpen, onOpen, onClose } = useModal();

  const { id, localizations = [] } = useSelector(currentTicketTextSelector)!;
  const cultures = useClassificationMap(ClassificationGroup.CULTURE);
  const cultureOptions = useClassificationOptions(ClassificationGroup.CULTURE);

  const usedTranslations = useMemo(
    () => localizations.map(({ culture }) => culture.id),
    [localizations]
  );
  const availableCultureOptions = useMemo(
    () =>
      cultureOptions.filter(({ value }) => !usedTranslations.includes(value)),
    [cultureOptions, usedTranslations]
  );
  const dispatch = useDispatch();

  const columns: TableColumns<TicketTextLocalization> = useMemo(
    () => [
      {
        id: 'culture.id',
        accessor: ({ culture }) => culture?.id,
        Header: <TransTableHead i18nKey="language" />,
        type: 'select',
        editableProps: ({ value }) => ({
          options: [
            value && { value, label: cultures.get(value) },
            ...availableCultureOptions,
          ].filter(Boolean),
        }),
        width: 100,
      },
      {
        id: 'name',
        accessor: 'name',
        Header: <TransTableHead i18nKey="shortDescription" />,
      },
      {
        id: 'localizedValue',
        accessor: ({ localizedValue }) =>
          localizedValue && localizedValue.replace(/<[^>]+>/g, ''),
        Header: <TransTableHead i18nKey="description" />,
        width: '50%',
      },
    ],
    [availableCultureOptions, cultures]
  );

  const { form } = useForm<{ rows: Array<TicketTextLocalization> }>({
    initialValues: {
      rows: localizations,
    },
  });

  const table = useFormTable<TicketTextLocalization>(
    {
      data: localizations,
      columns,
      form,
      onRowUpdate: (payload) =>
        dispatch(
          updateOrCreateTicketTextLocalization({ id, payload })
        ).unwrap(),
    },
    useFilters,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemoved = useCallback(
    async (rows: Array<TicketTextLocalization>) => {
      await dispatch(
        deleteTicketTextLocalizationsBulk({
          ids: rows.map(({ id }) => id).filter(Boolean),
          ticketTextId: id,
        })
      ).unwrap();

      dispatch(getTicketText(id));
      onClose();
    },
    [dispatch, id, onClose]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemoved,
  });

  return (
    <FormProvider {...form}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Button
          variant="text"
          startIcon={<Icon name="delete" />}
          onClick={onOpen}
          disabled={table.selectedFlatRows.length === 0}
          color="error"
        >
          <TransButton i18nKey="deleteSelected" />
        </Button>
        <Button
          variant="text"
          onClick={addRow}
          startIcon={<Icon name="plus" />}
        >
          <TransButton i18nKey="addNew" />
        </Button>
      </Stack>
      <Table
        getTableProps={{ sx: { tableLayout: 'fixed' } }}
        getHeaderProps={{ style: { backgroundColor: 'white' } }}
        table={table}
      />
      <ConfirmDeleteModal
        handleDelete={removeSelectedRows}
        title={<TransModal i18nKey="deleteTicketTextLocalizations" />}
        description={
          <TransModal i18nKey="ticketTextLocalizationsDeletionDescription" />
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </FormProvider>
  );
};
