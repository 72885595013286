import { Loadable, Layout, AddButton, CardHeader } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { FC } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { TransButton } from 'i18n/trans/button';
import { useSelector } from 'store/utils';
import { ticketTextsLoadingSelector } from 'features/loading/loadingSelectors';
import { TicketTextTable } from 'routes/TicketTexts/TicketTextTable';
import { TicketTextDetails } from 'routes/TicketTexts/TicketTextDetails';

interface TicketTextsProps {}

export const TicketTexts: FC<TicketTextsProps> = () => {
  const history = useHistory();
  const loading = useSelector(ticketTextsLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader title={<TransTitle i18nKey="ticketTexts" />}>
            <AddButton onClick={() => history.push('/ticket-texts/create')}>
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path="/ticket-texts/:action(create|edit)/:id?"
              component={TicketTextDetails}
              exact
            />
          </Switch>
        }
      >
        <TicketTextTable />
      </Layout>
    </Loadable>
  );
};
