import type { FC } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'store/utils';
import { getTTLConfigurations } from 'features/timeToLive/timeToLiveActions';
import { ttlConfigurationsLoadingSelector } from 'features/loading/loadingSelectors';
import { ttlConfigurationsSelector } from 'features/timeToLive/timeToLiveSelectors';
import {
  AddButton,
  CardHeader,
  Layout,
  Loadable,
  SearchResult,
} from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { CardContent, Grid } from '@mui/material';
import { TimeToLiveConfigurationCard } from 'routes/TimeToLive/TimeToLiveCard';
import { Route, Switch, useHistory } from 'react-router-dom';
import { TimeToLiveConfigurationDetails } from 'routes/TimeToLive/TimeToLiveDetails';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'TimeToLiveConfigurations',
  }
);

interface TimeToLiveConfigurationsProps {}

export const TimeToLiveConfigurations: FC<TimeToLiveConfigurationsProps> =
  () => {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getTTLConfigurations());
    }, [dispatch]);
    const loading = useSelector(ttlConfigurationsLoadingSelector);
    const data = useSelector(ttlConfigurationsSelector);

    const classes = useStyles();
    const history = useHistory();

    return (
      <Loadable loading={loading}>
        <Layout
          className={classes.root}
          header={
            <CardHeader title={<TransTitle i18nKey="timeToLive" />}>
              <AddButton onClick={() => history.push('/ttl/create')}>
                <TransButton i18nKey="add" />
              </AddButton>
            </CardHeader>
          }
          drawer={
            <Switch>
              <Route
                path="/ttl/:action(create|edit)/:id?"
                component={TimeToLiveConfigurationDetails}
                exact
              />
            </Switch>
          }
        >
          <SearchResult results={data.length} i18nKey="noDataAdded">
            <CardContent sx={{ p: 2, flexGrow: 1 }}>
              <Grid container spacing={3}>
                {data.map((item) => (
                  <Grid key={item.id} item sm={4} md={3}>
                    <TimeToLiveConfigurationCard ttl={item} />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </SearchResult>
        </Layout>
      </Loadable>
    );
  };
