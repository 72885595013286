import {
  Button,
  CardHeader,
  ConfirmDeleteModal,
  DrawerForm,
  DrawerFormProps,
  FormControl,
  FormProvider,
  Icon,
  PeriodField,
  SelectField,
  TextField,
  Tooltip,
  decimalToPercentage,
  formSubmit,
  formatPeriodDatesWithoutTime,
  percentageToDecimal,
  renderPercentageError,
  renderRangeError,
  useForm,
  useModal,
} from '@fleet/shared';
import { BookingFeeValues } from 'dto/bookingFee';
import {
  createBookingFee,
  deleteBookingFee,
  getBookingFee,
  getBookingFees,
  setBookingFee,
  updateBookingFee,
} from 'features/bookingFee/bookingFeeActions';
import { currentBookingFeeSelector } from 'features/bookingFee/bookingFeeSelectors';
import { bookingFeeLoadingSelector } from 'features/loading/loadingSelectors';
import { TransAlert } from 'i18n/trans/alert';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useAlert } from 'react-alert';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { omit } from 'lodash';
import {
  ButtonGroup,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransButton } from 'i18n/trans/button';
import { TransModal } from 'i18n/trans/modal';
import { TransField } from 'i18n/trans/field';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useCarrierInitValue } from 'hooks/useCarrierInitValue';
import { OwnerField } from 'components/OwnerField';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';

interface BookingFeeDetailsProps {}

export const BookingFeeDetails: FC<BookingFeeDetailsProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const { open: isOpen, onOpen, onClose } = useModal();

  const { action, id } = useParams<{ action: string; id?: string }>();
  const isEditing = useMemo(
    () => action === 'edit' && Boolean(id),
    [action, id]
  );

  const bookingFee = useSelector(currentBookingFeeSelector);
  const loading = useSelector(bookingFeeLoadingSelector);

  const salesChannelOptions = useClassificationOptions(
    ClassificationGroup.SALES_CHANNEL
  );
  const carrierOptions = useClassificationOptions(ClassificationGroup.CARRIER);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const carrierIdFormInitValue = useCarrierInitValue(carrierOptions);
  const productCategoryOptions = useClassificationOptions(
    ClassificationGroup.PRODUCT_CATEGORY
  );
  const currencyOptions = useClassificationOptions(
    ClassificationGroup.CURRENCY
  );

  const handleGoBack = useCallback(() => {
    history.replace('/booking-fee');
  }, [history]);

  const handleCloseEditForm: DrawerFormProps['onClose'] = useCallback(
    (_, reason) => {
      if (reason === 'close') {
        handleGoBack();
      }
    },
    [handleGoBack]
  );

  const ignoreFormRestart = useRef(false);

  useEffect(() => {
    !ignoreFormRestart.current && dispatch(setBookingFee());
    if (isEditing && id) {
      dispatch(getBookingFee(id)).unwrap().catch(handleGoBack);
    }

    return () => {
      !ignoreFormRestart.current && dispatch(setBookingFee());
    };
  }, [action, dispatch, handleGoBack, id, isEditing]);

  const initialValues = useMemo<BookingFeeValues>(
    () => ({
      ownerId: currentBusinessEntityId,
      carrierIds: carrierIdFormInitValue ? [carrierIdFormInitValue] : [],
      ...(bookingFee && {
        ...bookingFee,
        salesChannelId: bookingFee.salesChannel.id,
        carrierIds: bookingFee.carriers.map(({ id }) => id),
        productCategoryId: bookingFee.productCategory.id,
        feePercentage: decimalToPercentage(bookingFee.feePercentage),
        vatPercentage: decimalToPercentage(bookingFee.vatPercentage),
        currencyId: bookingFee.currency.id,
      }),
    }),
    [bookingFee, carrierIdFormInitValue, currentBusinessEntityId]
  );

  const onSubmit = useCallback(
    async (values: BookingFeeValues) =>
      formSubmit(async () => {
        const { id } = values;
        const data = await dispatch(
          (!id ? createBookingFee : updateBookingFee)({
            ...values,
            feePercentage:
              values.feePercentage && percentageToDecimal(values.feePercentage),
            vatPercentage:
              values.vatPercentage && percentageToDecimal(values.vatPercentage),
            validityPeriod: formatPeriodDatesWithoutTime(values.validityPeriod),
          })
        ).unwrap();

        if (!id) {
          alert.success(<TransAlert i18nKey="bookingFeeCreated" />);
          history.replace(`/booking-fee/edit/${data.id}`);
        } else {
          alert.success(<TransAlert i18nKey="bookingFeeUpdated" />);
        }

        dispatch(setBookingFee(data));
        dispatch(getBookingFees());
      }),
    [alert, dispatch, history]
  );

  const { form, handleSubmit, dirty, submitting } = useForm({
    initialValues,
    onSubmit,
    subscription: { dirty: true, submitting: true },
  });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  const handleCopy = useCallback(async () => {
    ignoreFormRestart.current = true;
    form.restart({
      ...omit(initialValues, ['id']),
    });
    history.replace(`/booking-fee/create`);
  }, [form, history, initialValues]);

  const handleDelete = useCallback(async () => {
    await dispatch(deleteBookingFee(id!)).unwrap();
    dispatch(setBookingFee());
    alert.success(<TransAlert i18nKey="bookingFeeDeleted" />);
    history.replace('/booking-fee/');
  }, [alert, dispatch, history, id]);

  return (
    <DrawerForm open onClose={handleCloseEditForm}>
      <FormProvider {...form}>
        <CardHeader
          isLight
          title={
            <Typography variant="subtitle">
              {isEditing ? (
                <TransSubtitle i18nKey="editBookingFee" />
              ) : loading ? (
                <>&nbsp;</>
              ) : (
                <TransSubtitle i18nKey="newBookingFee" />
              )}
            </Typography>
          }
          action={
            <ButtonGroup>
              {isEditing && (
                <>
                  <Button
                    variant="text"
                    startIcon={<Icon name="clone" />}
                    onClick={handleCopy}
                  >
                    <TransButton i18nKey="copy" />
                  </Button>
                  <Button
                    variant="text"
                    color="error"
                    startIcon={<Icon name="trash" />}
                    onClick={onOpen}
                  >
                    <TransButton i18nKey="delete" />
                  </Button>
                  <ConfirmDeleteModal
                    handleDelete={handleDelete}
                    title={<TransModal i18nKey="deleteBookingFee" />}
                    description={
                      <TransModal i18nKey="bookingFeeDeletionDescription" />
                    }
                    isOpen={isOpen}
                    onClose={onClose}
                  />
                </>
              )}
              <IconButton aria-label="close" onClick={handleGoBack}>
                <Tooltip content={<TransButton i18nKey="close" />} delay={500}>
                  <Icon name="close" size={24} />
                </Tooltip>
              </IconButton>
            </ButtonGroup>
          }
        />
        <CardContent component="form" onSubmit={handleSubmit}>
          <Grid container columns={1} spacing={2}>
            <Grid item xs={1}>
              <Grid container columns={4} spacing={2}>
                <Grid item xs={1}>
                  <OwnerField disabled />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    name="carrierIds"
                    label={<TransField i18nKey="carrier" />}
                    options={carrierOptions}
                    multiple
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    name="salesChannelId"
                    label={<TransField i18nKey="salesChannel" />}
                    options={salesChannelOptions}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    name="productCategoryId"
                    label={<TransField i18nKey="productCategory" />}
                    options={productCategoryOptions}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Grid container columns={4} spacing={2}>
                <Grid item xs={1}>
                  <TextField
                    name="feePercentage"
                    label={<TransField i18nKey="feePercentage" />}
                    validate={renderPercentageError}
                    type="number"
                    endAdornment={<TransField i18nKey="percent" />}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="vatPercentage"
                    label={<TransField i18nKey="vatPercentage" />}
                    validate={(value: number | string) =>
                      renderRangeError(value, 0, 100)
                    }
                    type="number"
                    endAdornment={<TransField i18nKey="percent" />}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Grid container columns={4} spacing={2}>
                <Grid item xs={1}>
                  <SelectField
                    name="currencyId"
                    label={<TransField i18nKey="currency" />}
                    options={currencyOptions}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="minFee"
                    label={<TransField i18nKey="minFee" />}
                    type="number"
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="maxFee"
                    label={<TransField i18nKey="maxFee" />}
                    type="number"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Grid container columns={3} spacing={2}>
                <PeriodField
                  from={{
                    name: 'validityPeriod.from',
                    label: <TransField i18nKey="validFrom" />,
                    required: true,
                  }}
                  to={{
                    name: 'validityPeriod.to',
                    label: <TransField i18nKey="validTo" />,
                    required: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" flexWrap="nowrap">
                <FormControl label="&nbsp;">
                  <Button
                    variant="text"
                    sx={{ whiteSpace: 'nowrap' }}
                    {...(!isEditing && { onClick: handleGoBack })}
                    {...(isEditing && {
                      onClick: handleReset,
                      disabled: !dirty,
                    })}
                  >
                    <TransButton
                      i18nKey={isEditing ? 'resetChanges' : 'cancel'}
                    />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button
                    variant="contained"
                    icon={isEditing ? 'check' : 'plus'}
                    type="submit"
                    disabled={
                      !ignoreFormRestart.current && (!dirty || submitting)
                    }
                  >
                    <TransButton i18nKey={isEditing ? 'save' : 'create'} />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </FormProvider>
    </DrawerForm>
  );
};
